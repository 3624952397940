<template>
    <div v-if="show" class="modal-overlay" @click="closeModalOutside">
        <div class="modal-content" @click.stop>
            <span class="close-button" @click="closeModal">&times;</span>
            <h2>{{ modalHeadMessage }}</h2>
            <pre>{{ modalMessage }}</pre>
        </div>
    </div>
</template>

<script>
export default {
    props: ['show', 'modalHeadMessage','modalMessage'],
    methods: {
        closeModal() {
            this.$emit('close');
        },
        closeModalOutside(event) {
            if (event.target === event.currentTarget) {
                // Si el clic ocurrió en la capa de fondo (modal-overlay), cierra el modal
                this.closeModal();
            }
        }
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Asegura que el modal esté encima de otros contenidos */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px; /* Bordes redondeados para el contenido */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra ligera */
    text-align: center;
    width: 400px; /* Ancho del modal */
    max-width: 90%; /* Máximo ancho para pantallas pequeñas */
    position: relative; /* Para que el modal no se desplace */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #555;
}

pre {
    font-size: 14px; 
    white-space: pre-wrap;
}
</style>
