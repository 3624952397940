<template>
    <div style="margin: 10px">
      <form ref="form">
        
        <div class="row">
          
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="nombres" class="form-label" aria-label="Nombres">
              Nombre(s) *</label
            >
            <input
              type="text"
              id="nombres"
              class="form-control"
              :class="{ error: $v.form.nombres.$error }"
              v-model.trim="$v.form.nombres.$model"
              aria-label="nombres"
              style="text-transform:uppercase"
            />
          </div>
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="appPat" class="form-label" aria-label="Apellido paterno">
              Apellido Paterno *
            </label>
            <input
              type="text"
              id="appPat"
              class="form-control"
              :class="{ error: $v.form.appPat.$error }"
              v-model.trim="$v.form.appPat.$model"
              aria-label="Apellido paterno"
              style="text-transform:uppercase"
            />
          </div>
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="appMat" class="form-label" aria-label="Apellido materno">
              Apellido Materno *
            </label>
            <input
              type="text"
              id="appMat"
              class="form-control"
              :class="{ error: $v.form.appMat.$error }"
              v-model.trim="$v.form.appMat.$model"
              aria-label="Apellido materno"
              style="text-transform:uppercase"
            />
          </div>
        </div>
  
        
       
        <div class="row">
          <div class="mb-3 col-lg-4 col-xs-12">
            <label
              for="correoElectronico"
              class="form-label"
              aria-label="Correo electrónico"
            >
              Correo electrónico *
            </label>
            <input
              type="text"
              id="correoElectronico"
              class="form-control"
              :class="{ error: $v.form.correo.$error }"
              v-model.trim="$v.form.correo.$model"
              aria-label="Correo electrónico"
            />
          </div>
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="telefono" class="form-label" aria-label="teléfono">
              Teléfono *
            </label>
            <input
              type="text"
              id="telefono"
              class="form-control"
              :class="{ error: $v.form.telefono.$error }"
              v-model.trim="$v.form.telefono.$model"
              aria-label="teléfono"
            />
          </div>
          <div class="mb-3 col-lg-4 col-xs-12">
            <label
              for="cp"
              class="form-label"
              aria-label="Código postal">
              Código Postal
            </label>
            <input
              type="text"
              id="cp"
              class="form-control"
              :class="{ error: $v.form.cp.$error }"
              v-model.trim="$v.form.cp.$model"
              aria-label="Código Postal"
            />
          </div>
        </div>
      </form>
    </div>
  </template>
  <script>
  import "vue-select/dist/vue-select.css";
  import {
    required,
    email,
    numeric,
  } from "vuelidate/lib/validators";
  import { mapState, mapActions, mapMutations } from "vuex";
  
  export default {
    props: ["clickedNext", "currentStep"],
    name: "GeneralFormInteresado",
    mounted() {
      // this.getStates();
      /* console.log("mounted", this.$v.$invalid); */
      this.setMunicipios([]);
      if (!this.$v.$invalid) {
        this.$emit("can-continue", { value: true });
      } else {
        // this.verTerminos();
        this.$emit("can-continue", { value: false });
      }
    },
    activated: function () {
      /* console.log("activate"); */
      if (!this.$v.form.$invalid) {
        // console.log("activate can");
        this.$emit("can-continue", { value: true });
      } else {
        // console.log("activate cant");
        this.$emit("can-continue", { value: false });
      }
    },
    deactivated: function () {
      // console.log("deactivated");
    },
    data() {
      return {
        form: {
          tipoDoc: {
            id: null,
            label: null
          },
         
          nombres: "",
          appPat: "",
          appMat: "",
          correo: "",
          telefono: "",
          cp: "",
          numDoc: "",
        },
      };
    },
    validations() {
      return {
        form: {
          nombres: {
            required,
          },
          appPat: {
            required,
          },
          appMat: {
            required,
          },
          correo: {
            required,
            email,
          },
          telefono: {
            numeric,
            required,
          },
          cp: {
            numeric,
            required,
          },
          
        },
      };
    },
    computed: {
      ...mapState("generals", [
        "organosJurisdiccionales",
        "cargosEleccionPopular",
        "tipoDocData",
        "generosData",
        "ciudades",
        "estados",
      ]),
      estadoSeleccionado() {
        return this.form.estado;
      },
    },
    methods: {
      ...mapMutations("contenido", ["setFormatoDatos"]),
      ...mapMutations("generals", ["setMunicipios",]),
      ...mapActions({
        getStates: "generals/getStates",
        getMunicipios: "generals/getMunicipios",
      }),
      emitirDatosCompletos() {
          this.$emit('datos-completos', this.form);
        },

        recopilarDatos() {
          return this.$refs.form;
        }
    },
    watch: {
      $v: {
        handler: function (val) {
          // console.log("es invalido cambio.... ", val.$invalid);
          if (!val.$invalid) {
            this.$emit("can-continue", { value: true });
            this.setFormatoDatos(this.form);
          } else {
            this.$emit("can-continue", { value: false });
          }
        },
        deep: true,
      },
      clickedNext(val) {
        if (val === true) {
          this.$v.form.$touch();
          // this.setFormatoDatos(this.form)
        }
      },
      
    },
  };
  </script>
  <style>
  .form-control {
    height: calc(1.5em + 0.75rem + 2px);
  }
  .vs__dropdown-toggle {
    border: 1px solid #eee;
    height: calc(1.5em + 0.75rem + 2px);
  }
  .v-select {
    height: calc(1.5em + 0.75rem + 2px);
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  }
  .error {
    border: 1px solid #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  </style>